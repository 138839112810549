/* eslint-disable no-nested-ternary */
import { type FC, type MouseEventHandler, useState } from 'react';
import { Link } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/client';
import {
  Box,
  forwardRef,
  HStack,
  type PropsOf,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { CompanyUserStatus, FeatureName } from '__generated__/GQL';
import { IS_PRODUCTION } from 'common/environment';
import useCanCompanyUserAccessFeature from 'common/hooks/useCanCompanyUserAccessFeature';
import { useCurrentCompany } from 'common/hooks/useCurrentCompany';
import { useUserCompanyList } from 'common/hooks/useUserCompanyList';
import SunshineDropdown from 'components/_core/SunshineDropdown';
import SunshineIcon from 'components/_core/SunshineIcon';
import Text from 'components/_core/Text';
import CompanyList from 'components/CompanyList';
import Loader from 'components/Loader';
import UserDropdownMenu from 'components/UserDropdownMenu';
import * as featureFlagsRoutes from 'features/FeatureFlags/routes';
import { UserMenuItem } from 'features/Sidebar/UserMenu/types';
import * as subscriptionRoutes from 'features/SubscriptionManagement/routes';

import useFeatureFlagsSwitcher from '../../../common/hooks/useFeatureFlagsSwitcher';
import locales from './locales';
import { UserInitialQueryDocument } from './userInitialQuery.gql';

interface UserMenuProps {
  disableBrowsingFor: Set<UserMenuItem>;
  forceNavigationReload?: boolean;
}

const UserDropdownMenuItem = (props: PropsOf<typeof UserDropdownMenu.Item>) => (
  <UserDropdownMenu.Item as={Link} {...props} />
);

const SwitchAccountSubMenu = forwardRef((props, ref) => {
  const apolloClient = useApolloClient();
  const { companies, loading } = useUserCompanyList();
  const styles = useMultiStyleConfig('SunshineDropdown', {
    colorScheme: 'grey',
  });

  if (loading || companies === undefined) {
    return <Loader />;
  }

  const handleCompanySelected = async () => {
    props.closeMainMenu?.();
    await apolloClient.resetStore();
  };

  return (
    <SunshineDropdown offset={[0, 16]} placement="right">
      <SunshineDropdown.Trigger
        {...props}
        _hover={{ color: 'text-primary' }}
        color="text-secondary"
        icon="switch"
        ref={ref}
      >
        <HStack width="100%">
          <Box __css={styles.itemIcon}>
            <SunshineIcon name="switch" size="icon-16" />
          </Box>
          <Text color="inherit">{locales.switchAccount}</Text>
          <SunshineIcon
            marginLeft="auto !important"
            name="chevron-right"
            size="icon-16"
          />
        </HStack>
      </SunshineDropdown.Trigger>
      <CompanyList companies={companies} onItemClick={handleCompanySelected} />
    </SunshineDropdown>
  );
});

const UserMenu: FC<UserMenuProps> = ({
  disableBrowsingFor,
  forceNavigationReload = false,
}) => {
  const { isLockedFromEveryCompany } = useUserCompanyList({
    excludeLockedAccess: false,
    fetchPolicy: 'cache-only',
  });

  const { data, error, loading } = useQuery(UserInitialQueryDocument);
  const { canAccessFeature } = useCanCompanyUserAccessFeature();
  const company = useCurrentCompany();
  const [hasSeenReferralsOffer, setHasSeenReferralsOffer] = useState(
    localStorage.getItem('hasSeenReferralsOffer') === 'true',
  );
  const { featureFlags } = useFeatureFlagsSwitcher();

  const navigationClickHandler:
    | MouseEventHandler<HTMLDivElement | HTMLButtonElement | HTMLAnchorElement>
    | undefined = forceNavigationReload
    ? (e) => {
        e.preventDefault();
        let anchor:
          | HTMLDivElement
          | HTMLButtonElement
          | HTMLAnchorElement
          | null = e.currentTarget;

        if (anchor.tagName !== 'A') {
          // Sometimes, the node is not always an anchor node (<a>). In this case, we need to find the anchor node.
          anchor = anchor.querySelector<HTMLAnchorElement>('a');
        }

        if (anchor) {
          const url = anchor.getAttribute('href') ?? '/';
          // We need to trigger a full page navigation load because when the ErrorBoundary is rendered,
          // the routes are not available anymore, and a reload is needed to reset the ErrorBoundary.
          window.location.assign(url);
        }
      }
    : undefined;

  if (error) {
    return <div>Oops! Erreur</div>;
  }

  if (loading || data === undefined) {
    return null;
  }

  const {
    viewer: {
      companyUsers,
      isNewOnboardingAllowed,
      profile: { firstName },
    },
  } = data;

  return (
    <>
      {hasSeenReferralsOffer ||
      !canAccessFeature({ name: FeatureName.Referral }) ? null : (
        <Box
          backgroundColor="#DB394C"
          border="1px solid #FFF"
          borderRadius="8px"
          height="8px"
          left="40px"
          position="absolute"
          width="8px"
          zIndex={2}
        />
      )}
      <UserDropdownMenu
        avatarSrc={company?.logoSrc}
        firstName={firstName}
        trackingName="Menu_Dropdown"
      >
        {({ onClose }) => (
          <>
            <UserDropdownMenuItem
              icon="profile"
              isDisabled={disableBrowsingFor.has(UserMenuItem.Profile)}
              onClick={navigationClickHandler}
              to="/profile"
            >
              {locales.profile}
            </UserDropdownMenuItem>

            {canAccessFeature({ name: FeatureName.CompanyProfileWrite }) ? (
              <UserDropdownMenuItem
                icon="company"
                isDisabled={disableBrowsingFor.has(UserMenuItem.Company)}
                onClick={navigationClickHandler}
                to="/company"
              >
                {locales.company}
              </UserDropdownMenuItem>
            ) : null}

            {featureFlags.enableSubscriptionManagement ? (
              <UserDropdownMenuItem
                icon="shine"
                isDisabled={disableBrowsingFor.has(UserMenuItem.Subscription)}
                onClick={navigationClickHandler}
                to={subscriptionRoutes.root}
              >
                {locales.subscription}
              </UserDropdownMenuItem>
            ) : null}

            {companyUsers.filter(
              ({ status }) => status !== CompanyUserStatus.Locked,
            ).length > 1 ? (
              <SunshineDropdown.Item
                as={SwitchAccountSubMenu}
                closeMainMenu={onClose}
                closeOnSelect={false}
              />
            ) : null}

            {isNewOnboardingAllowed ? (
              <UserDropdownMenuItem
                icon="plus"
                isDisabled={disableBrowsingFor.has(
                  UserMenuItem.CreateNewAccount,
                )}
                onClick={navigationClickHandler}
                to={
                  isLockedFromEveryCompany
                    ? '/onboarding'
                    : '/create-new-account'
                }
              >
                {locales.createNewAccount}
              </UserDropdownMenuItem>
            ) : null}

            <SunshineDropdown.Divider />
            {canAccessFeature({ name: FeatureName.Referral }) ? (
              <UserDropdownMenuItem
                icon="gift"
                isDisabled={disableBrowsingFor.has(
                  UserMenuItem.RecentActivities,
                )}
                onClick={() => {
                  localStorage.setItem('hasSeenReferralsOffer', 'true');
                  setHasSeenReferralsOffer(true);
                  navigationClickHandler;
                }}
                to="/referral"
              >
                {hasSeenReferralsOffer ? null : (
                  <Box
                    backgroundColor="#DB394C"
                    border="1px solid #FFF"
                    borderRadius="8px"
                    height="8px"
                    left="33px"
                    position="absolute"
                    width="8px"
                  />
                )}
                {locales.referral}
              </UserDropdownMenuItem>
            ) : null}

            {featureFlags.enableSecurityWithStrongAuthentication ? (
              <UserDropdownMenuItem
                icon="shield"
                isDisabled={disableBrowsingFor.has(UserMenuItem.Security)}
                onClick={navigationClickHandler}
                to="/security"
              >
                {locales.security}
              </UserDropdownMenuItem>
            ) : (
              <UserDropdownMenuItem
                icon="mobile"
                isDisabled={disableBrowsingFor.has(
                  UserMenuItem.RecentActivities,
                )}
                onClick={navigationClickHandler}
                to="/recent-activities"
              >
                {locales.recentActivities}
              </UserDropdownMenuItem>
            )}

            <UserDropdownMenuItem
              icon="logout-left"
              onClick={navigationClickHandler}
              to="/logout"
            >
              {locales.logout}
            </UserDropdownMenuItem>

            {!IS_PRODUCTION ? (
              <>
                <SunshineDropdown.Divider />

                <UserDropdownMenuItem
                  icon="settings"
                  onClick={navigationClickHandler}
                  to={featureFlagsRoutes.root}
                >
                  {locales.featuresFlags}
                </UserDropdownMenuItem>
              </>
            ) : null}
          </>
        )}
      </UserDropdownMenu>
    </>
  );
};

export default UserMenu;
